<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'

const props = defineProps<{
  defaultValue?: string | number
  min?: number
  max?: number
  modelValue?: string | number
  class?: HTMLAttributes['class']
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

function checkValueLimit(e) {
  if (props.min) {
    if (modelValue.value < props.min) {
      modelValue.value = props.min;
    }
  }
  if (props.max) {
    if (modelValue.value > props.max) {
      modelValue.value = props.max;
    }
  }
}

</script>

<template>
  <input
      v-bind="$attrs"
      v-model="modelValue"
      @input="checkValueLimit"
      :class="cn('flex h-10 w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:placeholder:text-neutral-400', props.class)">
</template>
